import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import { useWeb3React } from "@web3-react/core";
import Connect from "./Connect";
import Web3 from "web3";
import {ABI, ADDRESS} from "./utils";

const AdminPage = () => {
    const {active, account, library} = useWeb3React();
    const [address, setAddress] = useState();
    const [totalStake, setTotalStake] = useState(0);
    const [totalReward, setTotalReward] = useState(0);
    const [fee, setFee] = useState(0);
    const [userData, setUserData] = useState({reward: 0, percent: 0, days: 0})
    const [balance, setBalance] = useState(0)
    const [address2, setAddress2] = useState();
    const [startStake, setStartStake] = useState(1658523600); // 23.07.2022
    const [stakeBalance, setStakeBalance] = useState(0);
    const [stakeReward, setStakeReward] = useState(0);
    const [address3, setAddress3] = useState();
    const [banStatus, setBanStatus] = useState();

    const loadUser = (e) => {
        e.preventDefault();
        try {
            if(!library) return;
            const web3 = new Web3(library);
            const contract = new web3.eth.Contract(ABI, ADDRESS);
            contract.methods.stakeBalance(address).call().then(res=> {
                setBalance(res / Math.pow(10, 8))
            }).catch(err=> {
                alert(err);
            })
            contract.methods.calc(address).call().then(res=> {
                setUserData({reward: res[0] / Math.pow(10, 8), percent: res[1] / 100, days: res[2]})
            }).catch(err=> {
                alert(err);
            });
            contract.methods.blackList(address).call().then(res=> {
                setBanStatus(res.toString());
            }).catch(err=> {
                alert(err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    const getToken = async (e) => {
        e.preventDefault();
        try {
            if(!library) return;
            const web3 = new Web3(library);
            const contract = new web3.eth.Contract(ABI, ADDRESS);
            await contract.methods.adminSend().send({from: account}).then(()=> {
                alert('Done');
            }).catch(err=> {
                alert(err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    const banUser = async (e) => {
        e.preventDefault();
        try {
            if(!library) return;
            const web3 = new Web3(library);
            const contract = new web3.eth.Contract(ABI, ADDRESS);
            await contract.methods.addBlock(
                address3,
                true
            ).send({from: account}).then(()=> {
                alert('Done update block status');
            }).catch(err=> {
                alert(err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    const reBan = async (e) => {
        e.preventDefault();
        try {
            if(!library) return;
            const web3 = new Web3(library);
            const contract = new web3.eth.Contract(ABI, ADDRESS);
            await contract.methods.addBlock(
                address3,
                false
            ).send({from: account}).then(()=> {
                alert('Done update block status');
            }).catch(err=> {
                alert(err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    const updateUser = async (e) => {
        e.preventDefault();
        try {
            if(!library) return;
            const web3 = new Web3(library);
            const contract = new web3.eth.Contract(ABI, ADDRESS);
            await contract.methods.updateUser(
                address2,
                startStake,
                (stakeReward * Math.pow(10, 8)).toString(),
                (stakeBalance * Math.pow(10, 8)).toString()
            ).send({from: account}).then(()=> {
                alert('Done');
            }).catch(err=> {
                alert(err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    const updateFee = async (e) => {
        e.preventDefault();
        try {
            if(!library) return;
            const web3 = new Web3(library);
            const contract = new web3.eth.Contract(ABI, ADDRESS);
            await contract.methods.updateFee(fee * Math.pow(10, 18)).send({from: account}).then(()=> {
                alert('Done');
            }).catch(err=> {
                alert(err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const loadTotal = () => {
            try {
                if(!library) return;
                const web3 = new Web3(library);
                const contract = new web3.eth.Contract(ABI, ADDRESS);
                contract.methods.totalStake().call().then(res=> {
                    setTotalStake(res[0]);
                }).catch(err=> {
                    alert(err);
                });
                contract.methods.totalReward().call().then(res=> {
                    setTotalReward(res[0]);
                }).catch(err=> {
                    alert(err);
                });
                contract.methods.fee().call().then(res=> {
                    setFee(res / Math.pow(10, 18));
                }).catch(err=> {
                    alert(err);
                });
            } catch (e) {
                console.log(e);
            }
        }

        loadTotal();
    }, [account, library]);

    return (
        <Container>
            {!active ? (
                <Connect />
            ) : (
                <>
                    <Row className={'mt-5'}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    Admin address
                                </CardHeader>
                                <CardBody>
                                    <strong>{account}</strong>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={'mt-5'}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    Admin settings
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <strong>Stop contract</strong>
                                            <Button color={'danger'} block>Stop</Button>
                                        </Col>
                                        <Col>
                                            <strong>Get all token</strong>
                                            <Button color={'danger'} block onClick={(e)=>getToken(e)}>Get all token</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={'mt-5'}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    Ban user
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <FormGroup>
                                            <Label>Wallet address</Label>
                                            <Input type={'text'} id={'address-check'} value={address3} onChange={e=>setAddress3(e.target.value)}/>
                                        </FormGroup>
                                        <Button color={'danger'} onClick={e=>banUser(e)}>Banned</Button>
                                        <Button color={'primary'} onClick={e=>reBan(e)}>Re banned</Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={'mt-5'}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    Total information
                                </CardHeader>
                                <CardFooter>
                                    <Row>
                                        <Col>
                                            <strong>Stake: </strong><br /> {totalStake} XXP
                                        </Col>
                                        <Col>
                                            <strong>Reward: </strong><br /> {totalReward} XXP
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={'mt-5'}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    Fee ({fee} XXP)
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <FormGroup>
                                            <Input type={'text'} id={'fee'} value={fee} onChange={e=>setFee(e.target.value)}/>
                                        </FormGroup>
                                        <Button color={'primary'} onClick={e=>updateFee(e)}>Update fee</Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={'mt-5'}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    Check user address
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <FormGroup>
                                            <Label>Wallet address</Label>
                                            <Input type={'text'} id={'address-check'} value={address} onChange={e=>setAddress(e.target.value)}/>
                                        </FormGroup>
                                        <Button color={'primary'} onClick={e=>loadUser(e)}>Load address</Button>
                                    </Form>
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col>
                                            <strong>Balance: </strong><br /> {balance} XXP
                                        </Col>
                                        <Col>
                                            <strong>Reward: </strong><br /> {userData.reward} XXP
                                        </Col>
                                        <Col>
                                            <strong>Days: </strong><br />{userData.days}
                                        </Col>
                                        <Col>
                                            <strong>Percent: </strong><br />{userData.percent}%
                                        </Col>
                                        <Col>
                                            <strong>Banned: </strong><br />{banStatus}
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={'mt-5 mb-5'}>
                        <Col>
                            <Card>
                                <CardHeader>Update user</CardHeader>
                                <CardBody>
                                    <Form>
                                        <FormGroup>
                                            <Label>Wallet address</Label>
                                            <Input type={'text'} id={'address-update'} valu={address2} onChange={e=>setAddress2(e.target.value)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Stake balance</Label>
                                            <Input type={'text'} id={'stake-update'} value={stakeBalance} onChange={e=>setStakeBalance(e.target.value)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Reward balance</Label>
                                            <Input type={'text'} id={'reward-update'} value={stakeReward} onChange={e=>setStakeReward(e.target.value)}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Start stake</Label>
                                            <Input type={'text'} id={'start-stake-update'} value={startStake} onChange={e=>setStartStake(e.target.value)} />
                                        </FormGroup>
                                        <Button color={'primary'} onClick={e=>updateUser(e)}>Update user</Button>
                                    </Form>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </>
            )}
        </Container>
    )
}

export default AdminPage;